:root{
    --bg-content-color : #f6f6f6;
    --black-color : #000000;
    --white-color : #ffffff;
    --gray-color : #cccccc;
    --danger-color : #ff0f0f;
    --success-color : #56c080;
}

html.open-gtcookies,
body.open-gtcookies {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    overscroll-behavior: contain;
} 

.gtcookies-change-settings {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    text-align: center;
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 4999;
    background: var(--cp-accept-bg-color);
    cursor: pointer;

    i {
        font-style: normal;
        font-size: 30px;
        background: url('../img/cog-wheel-silhouette.png') no-repeat center center;
        background-size: auto;
        background-size: 25px 25px;
        width: 50px;
        height: 50px;
        display: block;
    }
    span {
        display: none;
    }
}

#gtcookies-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9998;
    width: 100%;
    height: 100%;
    background: var(--black-color);
    opacity: 0.6;
    display: none;
}
.gtcookies-display-modal-advanced {
    cursor: pointer;
}
#gtcookies-modal {
    display: none;

    &.open {
        &,
        & * {
            box-sizing: border-box;
            outline: none;
        }
        background: var(--cp-bg);
        color: var(--cp-color-font, inherit);
        position: absolute;
        z-index: 9999;
        border-radius: 15px;
        width: calc(100% - 30px);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
    
        .hide {
            display: none;
        }
        
        @media (max-width : 991px) {
            max-height: 90vh;

            .gtcookies-info {
                overflow-y: scroll;
                height: 50vh;
            }
        }

        @media (min-width : 992px) {
            width: 900px;
    
            &.gtcookies-center {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            &.gtcookies-top {
                top: 30px;
                left: 50%;
                transform: translateX(-50%);
            }
            &.gtcookies-bottom-left {
                top: auto;
                bottom: 30px;
                left: 30px;
                transform: none;
            }
            &.gtcookies-bottom {
                top: auto;
                bottom: 30px;
                left: 50%;
                transform: translateX(-50%);
            }
            &.gtcookies-bottom-right {
                bottom: 30px;
                top: auto;
                left: auto;
                right: 30px;
                transform: none;
            }
        }
    
        button {
            padding: 8px;
            font-weight: 600;
            font-size: 15px;
            cursor: pointer;
            border: none;
            position: relative;
            transition: all ease .3s;
            vertical-align: middle;

            @media (min-width : 992px) {
                font-size: 16px;
            }

            i {
                font-size: 14px;
                margin-right: 5px;
            }
            i,
            i::before,
            span {
                vertical-align: middle;
                display: inline-block;
            }
    
            &:hover {
                opacity: 0.8;
            }
        }

        .gtcookies-title {
            border-bottom: var(--gray-color) solid 1px;
            font-size: 16px;
            font-weight: 500;
            padding: 15px;
            line-height: 1.1;

            @media (min-width : 992px) {
                padding: 15px 30px;
                font-weight: 600;
                font-size: 20px;
            }
        }
    
        .gtcookies-info {
            padding: 15px;
            font-size: 15px;

            @media (min-width : 992px) {
                padding: 30px;
                font-size: 16px;
            }
    
            .logo {
                max-width: 100%;
                height: auto;
                margin-bottom: 8px;
            }
            
            p:last-child {
                margin-bottom: 0;
            }
        }
        .gtcookies-finalities {
            display: none;
    
            &.open {
                display: block;
            }
    
            .btn-cookie-validate {
                padding: 15px;
                border-top: var(--gray-color) solid 1px;
    
                > div {
                    display: flex;
                    flex-wrap: wrap;
                    margin-left: -15px;
                    margin-right: -15px;
    
                    div {
                        flex: 0 0 auto;
                        width: 100%;
                        max-width: 100%;
                        padding-left: 15px;
                        padding-right: 15px;
    
                        @media (min-width : 992px) {
                            flex: 50%;
                            width: 50%;
                        }
        
                        &:not(:last-child) {
                            margin-bottom: 15px;
        
                            @media (min-width : 992px) {
                                margin-bottom: 0;
                            }
                        }
        
                        button {
                            width: 100%;
    
                            &.gtcookies-save {
                                background: var(--cp-save-bg-color);
                                color: var(--cp-save-font-color);
                                border: var(--cp-save-border-color) solid 1px;    
                            }
                        }
                    }
                }
            }
    
            #gtcookies-finalities-container {
                max-height: 40vh;
                overflow-y: auto;
                overflow-x: hidden;
                border-top: var(--gray-color) solid 1px;
    
                > div {
                    display: flex;
                    flex-wrap: wrap;
                    margin-left: -15px;
                    margin-right: -15px;
        
                    .nav-cookie,
                    .content {
                        flex: 0 0 auto;
                        width: 100%;
                        max-width: 100%;
                        padding: 30px 45px;
                    }
    
                    @media (min-width : 992px) {
                        .nav-cookie {
                            flex: 25%;
                            width: 25%;
                            padding: 30px 30px 30px 45px;
                        }
                        .content {
                            flex: 75%;
                            width: 75%;
                            padding: 30px 45px 30px 30px;
                        }
                    }
    
                    .nav-cookie {
                        .logo {
                            max-width: 100%;
                            height: auto;
                            margin-bottom: 8px;
                        }
                        #gtcookie-tab {
                            margin: 0;
                            padding-left: 0;
                            list-style-type: none;
    
                            li:not(:last-child) {
                                margin-bottom: 15px;
                            }
                            a {
                                border-radius: 5px;
                                border: var(--gray-color) solid 1px;
                                padding: 8px;
                                display: block;
                                text-decoration: none;
                                color: inherit;
    
                                &.active {
                                    background: rgba(0, 0, 0, 0.065);
                                }
                            }
                        }
                    }
                    .content {
                        background: rgba(0, 0, 0, 0.065);
    
                        .gtcookies-tab-pane:not(.active) {
                            display: none;
                        }
    
                        .gtcookies-finality-switch-container {
                            margin-bottom: 15px;
    
                            .title {
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 1.2;
                                margin-bottom: 15px;
                            }
                        }
                        .text {
                            margin-bottom: 15px;
                        }
                        .content-table {
                            overflow-x: auto;
                        }
                        table {
                            border: var(--gray-color) solid 1px;
                            border-collapse: collapse;
                            border-spacing: 0;
                            margin-bottom: 15px;
    
                            th,
                            td {
                                border: var(--gray-color) solid 1px;
                                padding: 8px;
                            }
                        }
                    }
                }
            }
        }
        .gtcookies-actions {
            padding: 15px;
            border-top: var(--gray-color) solid 1px;
    
            > div {
                display: flex;
                flex-wrap: wrap;
                margin-left: -15px;
                margin-right: -15px;
    
                div {
                    flex: 0 0 auto;
                    padding-left: 15px;
                    padding-right: 15px;
                    flex: 50%;
                    width: 50%;
    
                    button,
                    a {
                        width: 100%;
    
                        &.gtcookies-accept {
                            background: var(--cp-accept-bg-color);
                            color: var(--cp-accept-font-color);
                            border: var(--cp-accept-border-color) solid 1px;
                        }
                        &.gtcookies-more-information {
                            background: var(--cp-more-information-bg-color);
                            color: var(--cp-more-information-font-color);
                            border: var(--cp-more-information-border-color) solid 1px;
                        }
                    }
                }
            }
        }
    }
}