/* Switch */
.gtcookies-finality-switch-container {
    display: inline-block;

    label {
        line-height: 24px;
        cursor: pointer;

        &[disabled="disabled"] {
            cursor: not-allowed;
        }
    }

    .gtcookies-finality-switch {
        display: inline-block;
        border-radius: 24px;
        height: 24px;
        width: 50px;
        position: relative;
        vertical-align: middle;
        transition: background 0.25s;
        margin: 0 6px;
    
        &::before,
        &::after {
            content: "";
        }
        &::before {
            display: block;
            background: var(--white-color);
            border-radius: 50%;
            position: absolute;
            transition: left 0.25s;
            top: 3px;
            left: 3px;
            width: 18px;
            height: 18px;
            line-height: 18px;
            text-align: center;
        }   
    }

    
    .gtcookies-finality-checkbox {
        position: absolute;
        visibility: hidden;

        &.disabled ~ .gtcookies-finality-switch,
        &:disabled ~ .gtcookies-finality-switch {
            cursor: not-allowed;
            opacity: 0.65;
        }

        &[value="na"]:checked ~ .gtcookies-finality-switch {
            background: var(--gray-color);

            &::before {
                left: 16px;
            }
        }
        &[value="off"]:checked ~ .gtcookies-finality-switch {
            background: var(--danger-color);
            
            &::before {
                content: '✘';
                color: var(--danger-color);
            }
        }
        &[value="on"]:checked ~ .gtcookies-finality-switch {
            background: var(--success-color);
            
            &::before {
                left: 27px;
                content: '✔';
                color: var(--success-color);
            }
        }
    }
}